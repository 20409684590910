// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      const blockchain = store.getState().blockchain
      let total = await store.getState().blockchain.smartContract.methods.getTotalPendingTokensOfAddress(blockchain.account).call();
      let totalPendingTokensOfAddress = Number(total)/Number(1000000000000000000)
      let tokensOfOwner = await store.getState().blockchain.smartContract.methods.tokensOfOwner(blockchain.account).call();


      dispatch(
        fetchDataSuccess({
          totalPendingTokensOfAddress,
          tokensOfOwner,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
